













































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Group, Permission } from "@/core/models";

import { DisplayError } from "@/components/common";
import { GroupItem, AddGroupCard } from "../components";

@Component({
  components: {
    DisplayError,
    GroupItem,
    AddGroupCard,
  },
})
export default class MyGroups extends Vue {
  @Getter("groups/loadingGroups") loadingGroups!: boolean;
  @Getter("groups/getGroups") groups!: Group[];
  @Getter("groups/retrieved") retrieved!: boolean;
  @Getter("profile/permissions") permissions!: Permission[];
  @Action("groups/getGroups") getGroups!: () => Promise<void>;

  created() {
    if (!this.retrieved) this.getGroups();
  }

  get() {
    this.getGroups();
  }

  get isTeacher() {
    return this.permissions?.some(x => x.type === "teacher");
  }
}
