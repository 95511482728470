










































































































































import Vue from "vue";
import { Group } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class GroupItem extends Vue {
  @Prop({ default: () => {} }) group!: Group;
  @Getter("groups/removeGroupLoading") removeGroupLoading!: boolean;
  @Getter("groups/removedGroupID") removedGroupID!: number;
  @Action("groups/removeGroup") removeGroupAction!: Function;
  @Action("groups/leaveGroup")
  leaveGroupAction!: (i: number) => Promise<void>;
  @Action("displaySnackbar")
  displaySnackbar!: (m: string) => void;

  leaveDialog = false;
  removeDialog = false;
  leaving = false;

  // Group helpers
  get userEmails() {
    let emails = this.group.Users.map(u => u.Email);
    if (emails.length > 3) return emails.slice(0, 3).join(", ") + ", ...";
    else return emails.join(", ");
  }

  get owner() {
    const user = this.group.Users.find(x => x.IsOwner);
    return user ? user.Email : "";
  }

  goToDetails() {
    this.$router.push("/groups/" + this.group.ID);
  }

  // Delete group
  removeGroup() {
    if (this.removeGroupLoading) return;
    this.removeDialog = false;
    this.removeGroupAction(this.group.ID);
  }

  // leave group
  async leaveGroup() {
    this.leaving = true;
    try {
      const msg = this.$t("groupItem.groupLeft", {
        name: this.group.Name,
      }).toString();
      await this.leaveGroupAction(this.group.ID);
      this.displaySnackbar(msg);
    } catch (error) {
      console.log(error);
    }
    this.leaveDialog = false;
    this.leaving = false;
  }
}
