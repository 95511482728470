




















import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import Invitations from "./components/GroupInvitations.vue";
import PublicGroups from "./components/PublicGroups.vue";
import { MyGroups } from "./components";
import { Permission } from "../../core/models";

@Component({
  components: {
    Invitations,
    PublicGroups,
    MyGroups,
  },
})
export default class Groups extends Vue {
  @Getter("groups/loadingGroups") loadingGroups!: boolean;
  @Getter("groups/loadingPublicGroups") loadingPublicGroups!: boolean;
  @Getter("profile/permissions") permissions!: Permission[];

  get isTeacher() {
    return this.permissions?.some(x => x.type === "teacher");
  }
}
