import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h4 font-weight-bold mb-6"},[_vm._v(" "+_vm._s(_vm.$t("groupsSections.yourGroupsPage.yourGroups"))+" ")]),_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c('div',{staticClass:"d-flex"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":"","loading":_vm.loadingGroups,"disabled":_vm.loadingGroups},on:{"click":_vm.get}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.refresh")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1),_c(VRow,[(_vm.isTeacher)?_c(VCol,{attrs:{"cols":"12","sm":"6","md":"8"}},[(_vm.groups.length == 0 && _vm.retrieved)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("groupsSections.yourGroupsPage.noGroups")))])]):_vm._e(),_vm._l((_vm.groups),function(group,i){return _c('GroupItem',{key:i,attrs:{"group":group}})})],2):_vm._e(),(_vm.isTeacher)?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('AddGroupCard')],1):_vm._e(),(!_vm.isTeacher)?_c(VCol,{attrs:{"cols":"12","md":"8"}},[(_vm.groups.length == 0 && _vm.retrieved)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("groupsSections.yourGroupsPage.noGroups")))])]):_vm._e(),_vm._l((_vm.groups),function(group,i){return _c('GroupItem',{key:i,attrs:{"group":group}})})],2):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }